<template>
    <div>
        <el-table
                :data="tableData"
                stripe
                style="width: 100%">
            <el-table-column width="300px"
                             prop="domainId"
                             label="页面">
                <template slot-scope="scope">
                    <span v-for="l of domainList" v-if="l.id == scope.row.domainId">{{l.pageName}}</span>
                </template>
            </el-table-column>
            <el-table-column
                    prop="type"
                    label="访问方式">
            </el-table-column>
            <el-table-column
                    prop="url"
                    label="请求地址">
            </el-table-column>
            <el-table-column
                    prop="ip"
                    label="来源IP地址">
            </el-table-column>
            <el-table-column
                    prop="createTime"
                    label="时间">
            </el-table-column>
        </el-table>
        <pagination :total="total" :page-size="10" :page.sync="queryParams.page"
                    :limit.sync="queryParams.limit" @pagination="recordList"/>

    </div>

</template>

<script>
    import * as api from "@/api/mini/recordLog";
    import * as apiDomain from "@/api/mini/pagedomain";
    import axios from "axios";

    export default {
        name: "sales",
        props: {
            queryParams: {
                page: 1,
                limit: 10
            },
        },
        data() {
            return {
                // 总条数
                total: 0,
                tableData: [],
                dialogFormVisible: false,
                dialogTitle: "",
                dialogForm: {
                    name: "",
                    detail: ""
                },
                dialogForm_null: Object.assign({}, this.dialogForm),
                rowIndex: 9999,
                imageUrl: '',
                adviserList: [],
                domainList: [],
                mediatorList: [],
                possList: [],
            }
        },
        mounted() {
            this.findDomain();
            this.recordList();
        },
        methods: {
            findDomain() {
                apiDomain.getList(this.queryParams).then(res => {
                    this.domainList = res.data
                });
            },
            exportRecord() {
                axios({
                    method: "post",
                    url: "/api/manage/record/log/exportRecord",
                    data: JSON.stringify(this.queryParams),
                    responseType: "blob",
                    headers: {
                        "Content-Type": "application/json;charset=utf-8",
                        "version": localStorage.getItem("_version"),
                    },
                }).then(res => {
                    let now = new Date();
                    const blob = new Blob([res.data]);
                    const fileName = '统计信息' + now.getFullYear() + now.getMonth() + 1 + now.getDate() + '.xlsx';
                    const elink = document.createElement('a')
                    elink.download = fileName;
                    elink.style.display = 'none';
                    elink.href = URL.createObjectURL(blob);
                    document.body.appendChild(elink);
                    elink.click();
                    URL.revokeObjectURL(elink.href); // 释放URL 对象
                    document.body.removeChild(elink);
                })
            },
            /** 搜索按钮操作 */
            handleQuery() {
                this.queryParams.page = 1;
                this.recordList();
            },
            /** 重置按钮操作 */
            resetQuery() {
                this.queryParams = {};
                this.handleQuery();
            },
            /*
            * 获取列表数据
            * */
            recordList() {
                api.getList(this.queryParams).then(res => {
                    this.tableData = res.data.data
                    this.total = res.data.count
                });
            },
            //重置表单规则
            resetFrom(roleForm) {
                this.$refs[roleForm].clearValidate();
            },
        }
    }
</script>

<style scoped>
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
</style>
